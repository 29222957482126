.loadingContainer-positioning {
  position: absolute;
  width: 100%;
  height: 100%;
}

.loadingContainer-table {
  width: 100%;
  height: 100%;
  display: table;
}

.loadingContainer-table-cell {
  text-align: center;
  display: table-cell;
  vertical-align: middle;
}

.loadingContainer-content {
  position: relative;
  z-index: 1;

  iframe {
    width: 100%;
    height: 80vh;

    &.form9Viewer {
      height: 500px;
    }
  }
}

.loadingContainer-container {
  position: relative;
  overflow: hidden;
}

.cf-loading-button-text {
  display: inline-block;
  margin-right: 10px;
  line-height: 0;
  position: relative;
}

.cf-loading-message {
  .loading-message-text {
    display: inline-block;
    font-style: italic;
    font-weight: bolder;
  }
}

.cf-loading-button-symbol {
  display: inline-block;
}
