.comment-control-button-container {
  display: flex;
  width: 100%;
  padding: 3px 0;
  justify-content: space-between;
}

.comment-save-button-container {
  display: inline-block;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 17px;
}

.comment-control-button {
  padding: 0;

  &.horizontal {
    text-align: left;
  }
}

.comment-control-button-divider {
  padding: 0 10px;
}

.comment-container-selected {
  box-shadow: 3px 3px 5px $color-gray-light;
}

.comment-container {
  word-wrap: break-word;
  word-break: break-word;
  background-color: $color-white;
  padding: 15px;

  &:not(.comment-horizontal-container) {
    margin-bottom: 15px;
    border-radius: 3px;
    border: 1px solid $color-gray-lighter;
  }

  &.comment-horizontal-container {
    padding: 25px 25px 25px 40px;
  }
}

// Max width is enforced so the size of this comment box
// fits with existing textareas in style guide
.comment-size-container {
  max-width: 430px;

  .cf-form-textarea {
    margin-bottom: 20px;
  }
}

.comment-horizontal-container {
  display: inline-block;
  width: 100%;
}

.comment-horizontal-spacing {
  margin-right: 49px;
}

.commentIcon-container {
  position: absolute;
  z-index: 10;
  // These dimensions need to match the comment icon SVG dimensions.
  width: 40px;
  height: 40px;
}
