.cf-toggle-button {
  span:not(:first-child):not(:last-child) {
    button {
      border-radius: 0;
    }
  }

  span {
    &:first-child {
      button {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        margin-left: 20px;
      }
    }

    &:last-child {
      button {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        margin-right: 20px;
      }
    }
  }

  .usa-button-secondary {
    background-color: $color-white;
    box-shadow: inset 0 0 0 2px $color-primary;
    color: $color-primary;
  }
}
