.assignment-list {
  .issue-list {
    padding-left: 0;
    margin-top: 0;
  }

  td {
    vertical-align: top;
    padding-top: 15px;
  }
}
