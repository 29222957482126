.cf-tab-navigation {
  margin-top: 2em;
  position: relative;
  width: 100%;
  border-bottom: 1px solid $color-primary;

  li {
    display: inline-block;
    margin: 0 -4px 0 0;
  }

  button {
    display: inline-block;
    margin: 0 -1px 0 0;

    &::-moz-focus-inner {
      // scss-lint:disable ImportantRule
      padding: 0 !important;
      border: 0 none !important;
      // scss-lint:enable ImportantRule
    }
  }

  div {
    display: inline-block;
    margin: 0 -1px 0 0;
  }
}

.cf-tab {
  box-sizing: border-box;
  border: 1px solid $color-gray-light;
  border-bottom: 0;
  border-radius: 0;
  background: $color-white;
  padding: 0;
  color: $color-gray;
  line-height: normal;
  font-weight: 500;

  > span {
    display: inline-block;
    padding: 15px 1.5em;
  }

  i {
    width: 17px;
    height: 17px;
    margin-right: 0.25em;
    margin-left: -0.4em;
  }

  svg {
    margin-right: 0.25em;
    margin-left: -0.4em;
    path {
      fill: $color-gray;
    }
  }

  &:hover {
    color: $color-gray-dark;
    background-color: $color-white;
    text-decoration: none;
    cursor: pointer;
  }

  &.disabled {
    background-color: $color-gray-lighter;
    color: $color-gray;
    font-weight: 500;

    &:hover {
      color: $color-gray;
      cursor: default;
    }
  }

  &:disabled:hover {
    color: $color-gray;
  }

  &:focus {
    svg path {
      fill: $color-primary;
    }
  }
}

.cf-active {
  color: $color-primary;
  border: 1px solid $color-primary;
  box-shadow: 0 1px 0 $color-white;
  border-bottom: 0;
  font-weight: 700;

  > span {
    top: 1px;
    background: $color-white;
    box-shadow: 0 2px 0 0 $color-primary inset;

    > span {
      top: -1px;
    }
  }

  + .cf-tab {
    border-left: 1px solid $color-primary;
  }

  svg path {
    fill: $color-primary;
  }
}

svg {
  &.cf-tab-check {
    vertical-align: -3px;
  }

  &.cf-tab-cross {
    vertical-align: -4px;
  }
}

.cf-pagination-pages {
  float: right;
  margin-bottom: 2em;
  text-align: right;
  width: 60%;

  button {
    background-color: $color-white;
    border-top: $color-gray-light 1px solid;
    border-bottom: $color-gray-light 1px solid;
    border-right: $color-gray-light 1px solid;
    border-radius: 0;
    color: $cf-link;
    font-weight: inherit;
    margin: 0;
    padding: 0.5em 0.7em;

    &:first-child {
      border-left: $color-gray-light 1px solid;
      border-radius: 0.15em 0 0 0.15em;
    }

    &:last-child {
      border-radius: 0 0.15em 0.15em 0;
    }

    &:disabled {
      color: $color-gray-light;
    }

    &.cf-current-page {
      background-color: $color-blue-darkest;
      color: $color-white;
    }
  }
}
