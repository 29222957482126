.usa-banner {
  background-color: $color-gold-lightest;
  text-align: center;
}

.usa-banner-inner {
  padding: 0.5em;
}

.banner-icon {
  display: inline-block;
  vertical-align: text-top;
  height: 25px;
}

.banner-text {
  padding-left: 10px;
}
