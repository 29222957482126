@mixin focus {
  &:focus {
    box-shadow: $box-shadow;
    outline: 0;
  }
}

.claim-detail-container {
  .claim-detail-type-info {
    color: $color-secondary;
    display: inherit;
  }
}

.rc-collapse > .rc-collapse-item-disabled > .rc-collapse-header {
  color: $color-base;
}

[id='claim-folder-details-accordion'] {
  .disabled {
    font-style: italic;
  }
}

.cf-pdf-page-container {
  position: absolute;
  top: 4em;
  bottom: 0;
  right: 0;
  left: 0;
  min-height: 400px;
  z-index: 0;
}

.cf-pdf-container {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 380px;
  left: 0;

  &.hidden-sidebar {
    right: 0;
    width: 100%;
  }
}

.cf-pdf-scroll-view {
  background-color: $color-gray;
  width: 100%;
  top: 50px;
  bottom: 50px;
  position: absolute;
  text-align: center;
  z-index: 5;
  will-change: transform;

  @include focus;
}

.cf-pdf-page {
  margin: 20px 0;
  width: 100%;
}

.annotationLayer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.cf-non-stylized-header {
  font-size: inherit;
  margin: 0;
  color: inherit;
}

.cf-sidebar-wrapper {
  display: flex;
  flex-direction: column;
  top: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  text-align: left;
  background: $caseflow-background;
  border-top: 1px solid $color-gray-lighter;

  &.hidden-sidebar {
    width: 0;
    min-width: 0;
  }

  .usa-accordion-content {
    background-color: $caseflow-background;
  }

  .usa-alert {
    margin-top: 15px;
  }
}

.cf-sidebar-header {
  padding-left: 20px;
  height: 50px;
  background-color: $color-gray-warm-light;
  line-height: 50px;
  color: $color-gray-dark;
}

.cf-category-sidebar {
  overflow-y: hidden;
}

.cf-issue-tag-sidebar {
  overflow: visible;

  .Select-menu-outer {
    position: relative;
  }
}

.cf-comment-wrapper {
  $comment-wrapper-vertical-margin: 15px;
  margin-top: $comment-wrapper-vertical-margin;

  .usa-alert {
    margin-top: 0;
    margin-bottom: $comment-wrapper-vertical-margin;
  }
}

.cf-pdf-toolbar {
  background: $color-gray-dark;
  width: 100%;
  height: 50px;
  color: $color-white;
  font-size: 15px;
  max-width: 100%;
  display: flex;
  justify-content: space-between;
}

.cf-pdf-header {
  position: absolute;
  opacity: 1;
  padding: 15px 23px 15px 15px;
  // .cf-pdf-scroll-view is 5, .cf-search-bar is 6
  z-index: 7;

  .category-icons-and-doc-type {
    display: flex;
    justify-content: center;
  }
}

.cf-pdf-footer {
  padding: 0 20px;

  button {
    display: flex;

    $button-label-spacing: 10px;

    .left-button-label {
      margin-left: $button-label-spacing;
    }

    .right-button-label {
      margin-right: $button-label-spacing;
    }
  }

  // We want the buttons to be 40px from the outer edge of the toolbar.
  // Other margins that are involved add up to 28px, so we add an
  // additional margin to make it 40.
  $left-outer-margin: 12px;
  $right-outer-margin: 20px;
  $footer-button-width: max-content;

  .cf-pdf-footer-buttons-right {
    width: $footer-button-width;
    margin-right: $right-outer-margin;
  }

  .cf-pdf-footer-buttons-left {
    width: $footer-button-width;
    margin-left: $left-outer-margin;
  }

  .cf-pdf-buttons-center {
    $spacing: 25px;
    margin-left: auto;
    margin-right: auto;

    .page-progress-indicator {
      margin-right: $spacing;
    }

    .page-progress-indicator-input {
      > input {
        width: 48px;
        height: 22px;
        display: inline-block;
        color: $color-white;
        background-color: $color-gray;
        border: 1px solid $color-white;
        text-align: center;
        margin: 5px;
        padding: 0;
      }

      > label {
        display: inline-block;
      }
    }

    .doc-list-progress-indicator {
      margin-left: $spacing;

      .filter-icon {
        margin-right: $spacing / 2;
        position: relative;
        top: 1px;
      }
    }
  }
}

.cf-comment-cursor {
  cursor: copy;
}

.cf-pdf-bookmarks {
  transform: scale(1.5, 2);
}

.cf-pdf-button {
  margin: 0 8px;
  color: inherit;
  padding: 0;
  background-color: transparent;
  font-size: inherit;
  pointer-events: all;
  line-height: normal;

  &:hover {
    text-decoration: none;
    background-color: transparent;
    color: $color-primary-alt;

    svg g {
      fill: $color-primary-alt;
    }
  }

  svg {
    vertical-align: bottom;
  }
}

.cf-pdf-spaced-buttons {
  margin: 0 18px;
}

.cf-pdf-edit-comment {
  float: right;
  position: relative;
  margin-right: -30px;
  overflow: visible;
}

.cf-pdf-edit-comment-icon {
  color: $color-primary;
  border: 2px solid $color-primary;
  border-radius: 3px;
  margin: auto;
  padding: 10px;
  background-color: $color-white;

  &:hover {
    box-shadow: 1px 1px 5px 0 $color-gray;
  }
}

.cf-sidebar-document-information {
  .cf-pdf-meta-doc-info-issues {
    padding-left: 1em;
    margin-top: 0.5em;
  }
}

.cf-sidebar-accordion {
  margin-bottom: 0;
  overflow: auto;

  .usa-accordion-content {
    padding: 14px 30px;

    .cf-form-checkboxes {
      margin-top: 6px;
      margin-bottom: 20px;

      .cf-form-checkbox {
        label {
          display: block;
        }
      }
    }
  }
}

.cf-documents-comments-control {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .cf-show-all-label {
    text-align: right;
  }

  // scss-lint:disable IdSelector
  #button-comments {
    margin-right: 0;
  }
}

.cf-add-comment-button {
  margin-bottom: 10px;
  width: 100%;
}

.cf-pdf-meta-title {
  margin: 0 0 10px;
}

.cf-full-page-app {
  width: 100%;
}

.pdfViewer.singlePageView .page {
  box-shadow: 0 0 5px $color-gray-dark;
  margin: 5px;
  margin-bottom: 25px;
  border-image: none;
  z-index: 5;
  overflow: hidden;
}

.cf-label {
  opacity: 0.3;
}

.cf-selected-label {
  opacity: 1;
}

.cf-pdf-comment-indicator {
  margin-left: 10px;
  font-size: 12px;
}

.cf-pdf-navigation {
  position: absolute;
  bottom: 50%;
  z-index: 20;
  pointer-events: none;
  left: 15px;
  right: 15px;
}

.cf-pdf-pdfjs-container {
  display: inline-block;
  position: relative;
}

// Use this class when you want the grid to start at the 1/3 point.
//    <empty> <content .cf-pull-right-one-third> <content>
.cf-pull-right-one-third {
  // This is a one-third width from .usa-width-one-third +
  //   the margin-right from .usa-width-one-third
  margin-left: 34.11921734%;
}

.cf-document-category-icons {
  list-style-type: none;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  float: left;

  li {
    float: left;
  }

  .highlighted {
    filter: drop-shadow(0 0 5px $color-primary-alt);
  }

  li:not(:first-child) {
    padding-left: 10px;
  }
}

.cf-pdf-pdfjs-textLayer {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  opacity: 0.2;
  line-height: 1;
}

.cf-pdf-annotationLayer {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.cf-pdf-cutoff {
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.cf-pdf-doc-type-button-container {
  flex: 0 1 auto;
  min-width: 20px;
}

.cf-pdf-doc-category-icons {
  flex: 0 0 auto;
}

.cf-pdf-doc-type-button {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
  width: 100%;
  padding-right: 5px;
}

.cf-pdf-placing-comment {
  outline: 10px solid $color-primary-alt;

  // We will manually draw the cursor with the annotation icon.
  * {
    // scss-lint:disable ImportantRule
    cursor: none !important;
    // scss-lint:enable ImportantRule
  }
}

.cf-pdf-vertically-center {
  display: flex;
  align-items: center;

  svg {
    display: block;
  }
}

.cf-pdf-external-link-icon {
  margin-left: 8px;
}

[type='button'].cf-pdf-download-icon {
  margin-left: 0;
}

// scss-lint:disable QualifyingElement
input[type='search'].cf-search-input-with-close {
  padding-right: 25px;
}
// scss-lint:enable QualifyingElement

.usa-search-small {
  [type='button'].cf-search-close-icon {
    margin-left: -20px;
    margin-top: 2px;
    position: absolute;
  }
}

.cf-pdf-page-hidden {
  visibility: hidden;
}

.cf-keyboard-shortcuts {
  bottom: 0;
  position: relative;
  text-align: center;
  margin: 10px 0;

  .cf-btn-link {
    &:hover {
      background-color: $caseflow-background;
    }
  }

  svg {
    vertical-align: bottom;
    margin-right: 12px;
  }
}

[id='cf-keyboard-modal'] {
  height: 580px;
  overflow: auto;
  display: block;
}

.canvasWrapper {
  background: $color-black;
}

.cf-modal-scroll {
  .cf-keyboard-modal-scroll {
    max-height: 370px;
    overflow: auto;
  }
}

.usa-table-borderless.cf-keyboard-modal-table > thead > tr > th {
  width: 200px;
}

.section--case-select {
  .welcome-header {
    margin-bottom: 10px;
  }

  .section-search {
    .usa-alert {
      display: flex;
      align-items: center;
      margin-top: 0;
      margin-bottom: 20px;
      padding: 0.5em;
      min-height: 70px;
    }
  }

  .folder-option {
    padding-left: 15px;

    .issues {
      padding-left: 1em;
      margin-top: 0.5em;
    }
  }
}

.cf-increment-search-match {
  height: 3.3rem;
  padding-top: 3px;
  width: 4rem;

  &.cf-prev-match {
    border-radius: 1rem 0 0 1rem;
  }

  &.cf-next-match {
    border-radius: 0 1rem 1rem 0;
    // scss-lint:disable ImportantRule
    margin-left: 5px !important;
    // scss-lint:enable ImportantRule
  }
}

.editable-field-btn-link {
  padding: 0 0.5rem 0.5rem 0.75rem;
}
